<template>
    <div>
        <card-header title="Billing" icon="fa-credit-card"/>
<!--        <card-toolbar>-->
<!--            <button><i class="fas fa-user-plus mr-2"/>New Customer</button>-->
<!--        </card-toolbar>-->

        <card-body>
            <card-list>
                <navigation-list-item
                    title="Plan Items Past Due"
                    icon="fa-history"
                    :badge="tasks.totals.bill_due"
                    @click="$openCard('billing-due', {}, card)"
                    :active="child && child.definition.component === 'billing-due'"
                />
                <navigation-list-item title="Recent Transactions" icon="fa-receipt" :badge="0"/>
                <navigation-list-item title="Expiring Credit Cards" icon="fa-credit-card" :badge="0"/>
                <navigation-list-item title="Uncompleted Tasks" icon="fa-tasks" :badge="0"/>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CustomerListItem from "../../components/CustomerListItem";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import CardBody from "@/TIER/components/CardBody";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import {client as http} from "@/http_client";
    import UserListItem from "@/components/CustomerListItem";
    import async from "async";
    import PlanItemListItem from "../../components/PlanItemListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import {mapGetters} from "vuex";

    export default {
        name: "Users",
        props: ['card', 'child'],
        components: {NavigationListItem, PlanItemListItem, SubheaderListItem, CardBody, SearchListItem, CardToolbar, CustomerListItem, CardList, CardHeader},
        data: function() {
            return {};
        },
        computed: {
            ...mapGetters(['tasks'])
        },
        methods: {
            loadBilling(force) {
                // this.$emit('loading', true);
                //
                // async.parallel([
                //     (cb) => {
                //         http.get('/api/billing/counts', {force}).then(response => {
                //             this.due = response.data;
                //             cb();
                //         });
                //     },
                //     // (cb) => {
                //     //     http.get('/api/users/created', {force}).then(response => {
                //     //         for (let i = 0; i < response.data.length; i++) {
                //     //             response.data[i].active = false;
                //     //         }
                //     //         this.newCustomers = response.data;
                //     //         cb();
                //     //     });
                //     // }
                // ], err => {
                //     this.$emit('loading', false);
                // });
            },
            reload: function() {
                this.loadBilling(true);
            }
        },
        mounted() {
            this.loadBilling();
        }
    };
</script>

<style lang="scss" scoped>

</style>
